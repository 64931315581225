<template>
    <div>
        <van-sticky>
            <title-bar2 title="查咨讯" />
            <title-search :value.sync="keyword" placeholder="请输入搜索资讯" @search="onSearch" />
        </van-sticky>
        <div class="article-box">
            <van-list class="school-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div v-for="(articleInfo, index) in articleInfos">
                    <div class="article-name" @click="onArticleClick(articleInfo)">{{ articleInfo.ArticleName }}</div>
                    <div class="article-price">发布时间：{{ formatDate(articleInfo.ArticleCreateTime) }}</div>
                    <div class="article-line"></div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            articleInfos: [],
        };
    },
    computed: {
        formatDate() {
            return function (str) {
                if (str) {
                    return Tools.formatDate(Tools.convertDate(str), 'yyyy-MM-dd')
                }
            }
        }
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //搜索
        onSearch() {
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.articleInfos = [];
            this.onLoad();
        },
        //加载当前页数据
        onLoad() {
            var model = {
                SearchKeyword: this.keyword,
                PageIndex: ++this.index,
                PageSize: 20,
            };
            this.$axios
                .post(Config.article.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.articleInfos.push(...data.ArticleInfos);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击文章
        onArticleClick(articleInfo) {
            this.$router.push({
                name: 'Article1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    articleGuid: articleInfo.ArticleGuid,
                }
            });
        },
    },
    mounted() {
        this.getRoutParams();
        this.onLoad();
    }
};
</script>
<style scoped>
.article-box {
    padding: 10px;
}

.article-refresh {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

.article-name {
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
}

.article-name:hover {
    color: #FE5E03;
}

.article-price {
    width: 100%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: gray;
    font-size: 14px;
}

.article-line {
    border-top: 1px solid #F6F6F6;
    margin: 10px 0px;
}
</style>