<template>
    <div class="content-box">
        <div class="content-left">
            <div class="search-control">
                <search-control placeholder="请输入考研资讯标题" :value.sync="keyword" @search-click="onSearchClick"></search-control>
            </div>
            <div class="content-border content-article">
                <div v-for="articleInfo in articleInfos" class="content-article-item">
                    <span class="content-article-item-name" @click="onArticleClick(articleInfo)">{{ articleInfo.ArticleName }}</span>
                    <span class="content-article-item-price">发布时间：{{ formatDate(articleInfo.ArticleCreateTime) }}</span>
                </div>
            </div>
            <div class="pagination-box">
                <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
        </div>
        <div class="content-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>
    
<script>
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolHot,
        SearchControl,
    },
    data() {
        return {
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            keyword: null,
            articleInfos: [],
        };
    },
    computed: {
        formatDate() {
            return function (str) {
                if (str) {
                    return Tools.formatDate(Tools.convertDate(str), 'yyyy-MM-dd')
                }
            }
        }
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //查询文章列表
        getArticleInfos() {
            var model = {
                SearchKeyword: this.keyword,
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
            };
            this.$axios
                .post(Config.article.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.articleInfos = data.ArticleInfos;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击文章
        onArticleClick(articleInfo) {
            this.$router.push({
                name: 'Article1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    articleGuid: articleInfo.ArticleGuid,
                }
            });
        },
        //点击搜索
        onSearchClick() {
            this.pageIndex = 1;
            this.getArticleInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getArticleInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getArticleInfos();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-border {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
}

.content-article {
    margin: 10px 0;
}

.content-article-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555;
    font-size: 14px;
    margin: 10px 0;
}

.content-article-item-name {
    width: calc(100% - 300px);
    cursor: pointer;
    color: #555;
    font-size: 14px;
}

.content-article-item-name:hover {
    color: #FE5E03;
}

.content-article-item-price {
    color: #555;
    font-size: 14px;
}

.search-control {
    width: 400px;
    margin: 10px 0;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}
</style>